#photos {
    columns: 300px;
    break-inside: avoid;
    margin-bottom: 30px;
    padding: 0;
    max-width: 100%;
}

@media screen and (max-width: 400px) {
  #photos {
    display: flex;
    flex-direction: column;
  }
}

.img-cell {
  height: 100%;
}

img {
    width: 100%;
    display: block;
    object-fit: cover;
    margin-top: 0px;
    margin-bottom: 20px;
    box-shadow: 1px 3px 8px rgba(0,0,0,0.5);
}

.image-instance {
  position: relative;
}

.icon-span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, rgba(0,0,0,.6) 0%, rgba(0,0,0,0.2) 60%, rgba(255,255,255,0) 100%);
  background-blend-mode: multiply;
  color: #BDECB6;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0 0 8px 8px;
}